.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: absolute;
  top: 70px;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.navigation {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  select {
    border: none;
    background: none;
    appearance: none;
    padding-left: 30px;
    margin-left: -20px;
    width: 140px;
    z-index: 1;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  nav {
    display: flex;
    align-items: center;
  }
}

.pending,
.loaded {
  transition: all 0.2s;
}

.pending {
  opacity: 0.7;
}

.yieldsheet_container {
  overflow: hidden;
  flex-grow: 1;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  #hot-display-license-info {
    display: none;
  }
}

.double_arrows {
  display: inline-flex;
  position: relative;
  &:first-child {
    margin-right: -4px;
  }
  span {
    position: absolute;
    left: 0;
    display: inline-flex;
  }
}

.dropdown_button {
  cursor: pointer;
}

.refresh-animation {
  animation-name: rotation;
  animation-duration: 0.8s;
  animation-timing-function: linear;
  animation-fill-mode: both;
}

@keyframes rotation {
  from {
    transform: rotate(-40deg);
  }
  80% {
    transform: rotate(340deg);
  }
  99.999% {
    transform: rotate(460deg);
  }
  to {
    transform: rotate(-40deg);
  }
}
