.fullSection {
  margin-top: 60px;
}

.pickup-from-row {
  display: flex;
  align-items: center;
  height: 0px;
}

@media (max-width: 1600px) {
  .fullSection {
    margin-top: 120px;
  }
}

@media (max-width: 1000px) {
  .fullSection {
    margin-top: 150px;
  }
}

@media (max-width: 600px) {
  .fullSection {
    margin-top: 200px;
  }
}
