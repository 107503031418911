.popupBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.bulkUpdateContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 80%;
  transform: translate(-50%, -50%);
  background-color: white;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  z-index: 1000;
  overflow: scroll;
}

.bulkValidation {
  position: absolute;
  color: red;
  margin-top: 5px;
}

.sectionTitle {
  font-size: 20px;
  font-weight: bold;
  background-color: black;
  color: white;
  width: 100%;
}

.sectionTitle h3 {
  padding: 10px;
}

.startDateMUIclass {
  margin-left: 2rem;
}

.dateStartandEnd {
  width: 10rem !important;
  padding: 8.5px 14px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1.4375em;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin: 4px 0;
  margin-left: 5rem !important;
}

.dateStartandEnd.to-date {
  margin-left: 2rem !important;
}

.dateStartandEnd:hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.dateStartandEnd:focus {
  outline: none;
  border-color: #1976d2;
  border-width: 2px;
}

.date-label {
  margin-left: 5rem !important;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
}

.date-label-to {
  margin-left: 2rem !important;
}

.bulkUpdateTop {
  margin-bottom: 1rem !important;
}

.bulkUpdateTopLabel {
  margin-top: 1rem !important;
  margin-left: 1rem;
}

.UpdateClass {
  width: 11rem !important;
}

.weekDaysChecked {
  margin-left: 4rem !important;
}

.filterSearch {
  margin-bottom: 2rem !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker__input-container {
  display: inline-block;
}

/* Fix dialog scrolling */
.MuiDialogContent-root {
  overflow-y: auto !important;
}

.MuiDialogActions-root {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 1;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}
