.hotelConfig {
  margin-top: 7vh;
}
.input-field {
  margin: 10px !important;
  width: 500px !important;
}

.dropdown-field {
  width: 100% !important;
}

.formControl {
  width: 34%;
  margin: 8px !important;
}

.globalSetting {
  display: flex;
  flex-direction: row;
}

.channel-manager {
  display: flex;
  flex-direction: column;
}

.siteMinderButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.siteMinderButton label {
  margin: 3vh 0 3vh 0;
  font-weight: bold;
  font-size: 15px;
}

.siteMinderButton input {
  margin: 3vh 0 3vh 0.7vw;
  background-color: rgb(100, 99, 99);
  color: white;
  padding: 0 10px 0 10px;
  border-radius: 5px;
  font-weight: bold;
}

.synclabelBox {
  height: min-content;
  transform: translate(12px, 0);
}

.syncDiv {
  height: min-content;
  position: relative;
  top: 25px;
}

@media (max-width: 959px) {
  .syncDiv {
    height: min-content;
    position: relative;
    top: 0px;
    left: 15px;
  }
  .synclabelBox {
    transform: translate(10px, 10%);
  }
}
