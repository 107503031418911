$colors: (
  "gray": gray,
) !default;

.logo {
  height: 28px;
}

.spacer {
  margin-top: 50px;
}
