.badge {
  position: absolute;
  border: 1px solid black;
  border-radius: 1px;
  top: 1px;
  left: 2px;
  /* background-color: rgb(204, 63, 83); */
  background-color: white;
}

.fieldsStyle {
  display: flex;
}

input[disabled] {
  background-color: #d3d3d3;
}
