/*
  Bootstrap CSS variables customization
  and styles overrides
 */

@import "variables";

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
);

/*
  Colors ======================
 */

$red: $cherry;
$dark: #000;
$primary: $red;

/*
  Fonts =======================
 */

$font-family-sans-serif: "Lato", sans-serif;
$font-family-base: "Lato", sans-serif;

// Bootstrap doesn't seem to care about the above overrides
// so this needs to be set by hand,
// and with this (0,0,2) specificity
html body {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  color: $black;
}

label {
  font-size: 1rem;
  margin: 0.1rem;
}

$h1-font-size: 30px;
$h2-font-size: 20px;
h2[class],
.h2[class] {
  font-weight: bold;
}

/*
  Buttons =====================
 */

$btn-line-height-lg: 1.2;
$btn-padding-x-lg: 30px;
$btn-padding-y-lg: 15px;

/*
  Nav =========================
 */

$nav-link-padding-y: 0.1rem;

/*
  Other =======================
 */

$border-radius-lg: 6px;

.card {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
}
