/* .dropdown-label{
    width: 80%;
    margin-top: 1px;
    margin-right: -10px;
    width: 150px;
    margin-bottom: 2px; 
    margin-left:345px;
}

.dropdown-text{
    margin-top: 1px;
    background: #3f51b5;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    background-position: right 1rem center;
    display: block;
    width: 100%;
} */

/* .text-properties{
    color: white !important;
    width:100%;
} */

.MuiTableCell-alignLeft-334 {
  text-align: center !important;
}

.MuiTableRow-root-320 {
  text-align: center !important;
}

.MuiTable-root-725 {
  text-align: center !important;
}
.MuiTableHead {
  text-align: right !important;
}
.MuiTableRow-head-323 {
  text-align: center !important;
}
/* .MuiTableCell-root-327 MuiTableCell-head-328 MTableHeader-header-320 MuiTableCell-alignLeft-334{
    text-align:right !important;
} */

.dropdown-select {
  /* outline: none; */
  /* border: 1px solid red; */
  /* display: block; */
  width: 25% !important;
  height: 40px;
  margin-top: 0.5vh;
  margin-left: 0% !important;
  font-size: 1em;
  padding: 0rem 0rem;
  /* border: 1px solid #333; */
  /* font-family: inherit; */
  /* background-color: #808080; */
  color: #ffffff !important;
  text-align: center;
  border-radius: 0.25rem;
  border: none;
  outline: none;
}

.dropdown-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.defCompset {
  width: 20%;
  text-align: right;
}

.dropdown-select-label {
  margin-right: 10px;
}

.info-icon {
  /* margin-top: 2vh; */
  margin-left: 0.5vw;
}
/* .dropdown-select:after {
    background-image:
      linear-gradient(45deg, transparent 50%, white 50%),
      linear-gradient(135deg, white 50%, transparent 50%),
      linear-gradient(to right, white, white);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      100% 0;
    background-size:
      5px 5px,
      5px 5px,
      2.5em 2.5em;
    background-repeat: no-repeat;
  }

 */
/* .MuiTableCell{
    text-align: center !important;
} */

/* Media Query */

@media only screen and (max-width: 1400px) {
  .defCompset {
    width: 25%;
  }
}

@media only screen and (max-width: 1200px) {
  .defCompset {
    width: 30%;
  }
}
