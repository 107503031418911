.h {
  text-align: center;
  font-weight: bold;
  font-size: 50px;
  margin-top: 50px;
}

._loading_overlay_overlay {
  position: fixed !important;
}

.main-div {
  border: 2px black solid;
  padding: 10px;
  padding-bottom: 40px;
  margin: 30px;
}

.main-div h2 {
  padding: 10px;
  font-size: 30px;
}

.btn-cancel {
  margin-right: 3vw;
  padding: 6px 25px 6px 25px;
  margin-bottom: 3vh;
  border-radius: 3px;
  font-weight: bold;
}

.btn-submit {
  padding: 6px 25px 6px 25px;
  background-color: #cc3f53;
  color: white;
  border: none;
  border-radius: 3px;
  font-weight: bold;
}

.warning {
  text-align: center;
  width: 41%;
  height: 23vh;
  background-color: white;
  position: fixed;
  left: 30vw;
  top: 15vh;
  z-index: 1;
  border: 1px solid black;
}

.warning4 {
  text-align: left;
  width: 41%;
  height: 20%;
  background-color: white;
  position: fixed;
  left: 30vw;
  top: 8vh;
  border: 1px solid black;
}

.warning h3 {
  margin-top: 12px;
}

.warning h4 {
  padding: 10px;
  margin-top: 15px;
}

.btn-cancel2 {
  margin-right: 3vw;
  padding: 6px 25px 6px 25px;
  margin: 3vh;
  border-radius: 3px;
  font-weight: bold;
}

.btn-proceed {
  background-color: #cc3f53;
  color: white;
  border: none;
  border-radius: 3px;
  font-weight: bold;
  margin-left: 100px;
  margin-right: 10px;
  float: right;
}

.redirect-btn {
  background-color: #ce0e29;
  color: white;
  border: none;
  border-radius: 3px;
  font-weight: bold;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 2px;
  float: right;
}
