.rate_plan[class] {
  padding-left: 2.5em;
  position: relative;

  :global(.form-check-input) {
    left: 25px !important;
  }
}

.red[class] {
  color: crimson;
  font-weight: bold;
}

.overridden_icon {
  font-size: 13px;
  position: relative;
  left: 6px;
  top: -5px;
  color: #999;
}

:global(.bulk-update-preview) {
  th {
    padding-left: 25px !important;
    position: relative;

    :global(.form-check-input) {
      position: sticky;
      left: 5px;
      top: auto;
      margin-top: 10px !important;
      z-index: 1;
    }

    .label {
      display: flex;
      align-items: center;
      padding-left: 25px;
      position: static;

      button {
        margin-right: 8px;
      }
    }
  }

  td {
    position: relative;
  }
}

.label {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 25px;
  position: relative;
  cursor: pointer;

  button {
    margin-right: 8px;
  }
}

:global(.form-check-input) {
  position: absolute !important;
  left: 5px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  margin: 0 !important;
  z-index: 1;
}

.rate_plan[class] {
  padding-left: 2.5em;
  position: relative;

  :global(.form-check-input) {
    left: 25px !important;
  }
}
