.matrixHeader {
  margin-top: 2vh;
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 1087px) {
  .btn-outline-dark {
    margin-top: 2vh !important;
    /* margin-left: 0 !important; */
  }
}

@media only screen and (max-width: 1364px) {
  .btn-outline-primary {
    margin-top: 2vh !important;
  }
}
