@import "variables";
.ht_clone_top_left_corner {
  display: none !important;
}

.handsontable {
  td,
  th {
    font-size: 0.85rem;
    border-color: darkgray;
    white-space: nowrap;
    vertical-align: middle;
  }
  td:not(:last-child),
  th {
    border-right: solid 1px darkgray;
  }
  textarea {
    line-height: 2.3em;
  }
  .base-room {
    border-top: solid 2px #333;
    background: $orange-yellow;
  }
  .no-wrap {
    white-space: nowrap;
  }
  .neutralblue {
    background: #becbda;
    color: black;
  }
  .marketDemand {
    background: #e7e8d0;
    color: black;
  }
  .strong {
    font-weight: bold;
  }
  .black {
    color: $black !important;
  }
  .blue {
    color: $blue !important;
  }
  .focus {
    color: $orange-yellow;
  }
  .muted {
    background: #eee;
  }
  .blackInverse {
    background: $black;
    color: $white;
    &.padded {
      border-right: solid 2px $white;
    }
  }
  .inverse {
    background: $dark;
    color: $white;
    &.padded {
      border-color: darkgray;
    }
  }
  .overriden {
    background: #ffffff;
    color: $black;
    &.padded {
      border-right: solid 2px $white;
    }
  }
  .overridenEdited {
    background: #ffffff;
    color: $red;
    &.padded {
      border-right: solid 2px $white;
    }
  }
  .overridenManual {
    background: #bfbfbf;
    color: $black;
    &.padded {
      border-right: solid 2px $white;
    }
  }
  .overridenManualEdited {
    background: #bfbfbf;
    color: $red;
    &.padded {
      border-right: solid 2px $white;
    }
  }
  .overriden:hover .overidetooltip {
    display: block;
  }
  .overridenEdited:hover .overidetooltip {
    display: block;
  }
  .overridenManual:hover .overidetooltipManual {
    display: block;
  }
  .overridenManualEdited:hover .overidetooltipManual {
    display: block;
  }
  .otaRow {
    background: #becbda;
  }

  .lastRow {
    background-color: red;
  }

  .otaRowFlag {
    width: 0;
    color: red;
    position: relative;
    height: 0;
    bottom: 32px;
    left: 89px;
    transform: rotate(19deg);
  }

  .otaSpanClass {
    width: 100% !important;
    height: 100% !important;
    font-size: 0.85rem;
    padding-top: 9px !important;
    margin-bottom: 0px !important;
  }

  .otaRow:hover .orignalTooltip {
    display: block;
  }
  .otaRow:hover .compareRoomNameDiv {
    display: block;
  }

  .otaRow:hover .roomToolTip {
    display: block;
  }

  .prevTooltip {
    position: absolute;
  }

  .otaRow:hover .lastOtaRate {
    display: block;
  }

  .otaRow:hover .lastCellPresentOta {
    display: block;
  }

  .otaRow:hover .singleRoomNameEx {
    display: block;
  }

  .lastRow {
    background: red;
  }

  .dp {
  }

  .orignalTooltip {
    background-color: white;
    padding-right: 1px;
    padding-left: 1px;
    display: none;
    color: black;
    position: relative;
    top: 11px;
    z-index: 9999 !important;
    overflow: visible;
    white-space: nowrap;
    word-wrap: break-word;
    width: 98px;
    white-space: break-spaces;
  }
  .compareRoomNameDiv {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 26px;
    // white-space: nowrap;
    word-wrap: break-word;
    width: 98px;
    white-space: break-spaces;
    display: none;
  }

  .upTooltipPrev {
    background-color: white;
    padding-right: 1px;
    padding-left: 1px;
    display: block;
    color: black;

    z-index: 9999 !important;
    overflow: visible;
  }

  .upTooltip {
    background-color: white;
    padding-right: 1px;
    padding-left: 1px;
    display: block;
    color: black;
    // position: relative;
    // display: flex;
    // flex-direction: row;
    z-index: 9999 !important;
    overflow: visible;
    // white-space: nowrap;
    // word-wrap: break-word;
    // width: 98px;
    // white-space:break-spaces;
    // bottom: 100px;
  }

  .singleRoomNameEx {
    background-color: white;
    padding-right: 1px;
    /* padding-left: 1px; */
    display: none;
    color: black;
    position: absolute;
    bottom: 40px;
    width: 98px;
    z-index: 9999;
    overflow: visible;
    white-space: nowrap;
    word-wrap: break-word;
    white-space: break-spaces;
    transform: translate(1px, 13px);
  }

  .roomToolTip {
    background-color: white;
    padding-right: 1px;
    padding-left: 1px;
    display: none;
    color: black;
    position: absolute;
    z-index: 9999 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
    width: 98px;
    white-space: break-spaces;
    margin-top: 11px;
  }

  .otaCompareDataLess {
    color: red;
    position: relative;
    float: right;
    overflow: visible;
    white-space: nowrap;
    width: min-content;
    margin-right: 5px;
    font-weight: bold;
  }

  .otaCompareDataMore {
    color: green;
    position: relative;
    float: right;
    overflow: visible;
    white-space: nowrap;
    width: min-content;
    margin-right: 5px;
    font-weight: bold;
  }

  .inverse.edited {
    background: $dark;
    color: $red;
    &.padded {
      border-right: solid 2px $white;
    }
  }
  .weekend {
    filter: brightness(0.95);
  }
  .disabled {
    filter: brightness(0.8);
  }
  .white {
    color: $white;
  }
  .rate {
    position: relative;
    div {
      display: flex;
    }
  }
  .availibility {
    position: relative;
    div {
      display: flex;
    }
  }
  .availibility.edited {
    color: $red;
  }
  .rate.edited {
    color: $red;
  }
  .rate.edited.discounted {
    color: $purple;
  }
  .rate.discounted {
    color: $blue;
  }
  .pickup {
    background-color: #ffd41f;
  }
  .pickupFrom {
    background-color: #ffff33;
    color: black;
  }
  .leftToSell {
    background-color: $orange-yellow;
    color: black;
  }
  .fullRight {
    margin: auto;
    margin-right: 0;
    float: right;
  }
  .fa-blue {
    color: rgb(59, 91, 152);
  }
  .fa-red {
    color: #ce0e29;
  }
  .manualinverse:after {
    color: #ce0e29;
    z-index: -1;
    content: "";
    font-size: 25px;
    width: 100%;
    height: 100%;
    text-align: left;
    font-weight: bolder;
    opacity: 0.8;
  }
  .manual:after {
    color: lightgray;
    z-index: -1;
    content: "";
    font-size: 20px;
    width: 20px;
    height: 20px;
    text-align: left;
    line-height: 20px;
    opacity: 1;
  }
  .rate.unpublished {
    color: $blue;
  }
  .rate.unpublished {
  }
  .rate.suggested-rate {
    color: #ff6500;
    font-weight: bolder;
    div {
      display: flex;
    }
  }
  .calendar-events {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8em;
  }
  .event-cell-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 4px;
  }
  .event-cell-content-text {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8em;
    line-height: 1.2em;
  }
  display: inline-block;
}

.ht_master .wtHolder {
  overflow: hidden !important;
}

.apply-btn {
  font-size: 11px;
  margin-left: 0.5em;
  padding: 0 0.4em 0.06em;
}

.restriction-wrapper {
  display: inline-flex;
  width: 80%;
  float: left;
}

.restriction {
  $size: 18px;
  display: inline-block;
  width: $size;
  height: $size;
  line-height: $size;
  border-radius: 4px;
  margin-left: 6px;
  margin-right: -3px;
  background: #b9c6d7;
  font-size: 10px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
}

.overidetooltip {
  background-color: #ffffff;
  padding-right: 10px;
  padding-left: 10px;
  display: none;
  color: black;
  margin-left: 28px;
  margin-top: 9px;
  position: absolute;
  z-index: 1000;
}

.overidetooltipManual {
  background-color: #bfbfbf;
  padding-right: 10px;
  padding-left: 10px;
  display: none;
  color: black;
  margin-left: 28px;
  margin-top: 9px;
  position: absolute;
  z-index: 1000;
}
