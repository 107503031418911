.notFound {
  width: 100%;
  position: fixed;
  height: 100%;
  z-index: 1000;
  background-color: white;
}

.notFoundMainDiv {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.row1 {
  display: flex;
  flex-direction: row;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  width: min-content;
}

.text404 {
  font-weight: bold;
  font-size: 140px;
  height: min-content;
  margin-top: 22px;
}

.notFoundImg {
  width: 200px;
  height: 200px;
}

.sorryText {
  font-size: 50px;
}

.notFoundButton {
  margin-top: 20px;
  color: #fff;
  background-color: #ce0e29;
  border-radius: 3px;
  border: none;
  height: 40px;
  padding-top: 0.375rem;
  padding-right: 0.75rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
}
