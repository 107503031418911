@import "variables";

.react-datepicker-wrapper {
  input {
    cursor: pointer;
    // border: none;
    width: 140px;
    text-align: center;
    &:focus {
      outline: none;
      color: transparent;
      text-shadow: 0 0 0 $black;
    }
  }
}
