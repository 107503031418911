@import "variables";

.Toastify__toast {
  border-radius: 4px;
}

.Toastify__toast-body {
  font-size: 16px;
  text-align: center;
  color: #333;
}

.Toastify__close-button {
  display: none;
}

.Toastify__toast--error {
  background: $red;
  .Toastify__toast-body {
    color: #fff;
  }
}
