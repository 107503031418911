/*
  Custom SASS variables
  to be used in other stylesheets
 */

$orange-yellow: #ffa400;
$cherry: #ce0e29;
$indigo: #c19ff9;
$white: #fff;
$gunmetal: #4c5d62;
$dark: #030235;
$black: #000;
$success: #1cc88a;
$info: #fff3cd;
$warning: #ffa400;
$danger: #ce530e;
$statusActive: #f28140;
$statusInactive: #6c757d;
